@import url(https://fonts.googleapis.com/css2?family=Varta:wght@300;400;500;600;700&display=swap);
body {
	margin: 0;
	font-family: 'Varta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body, #root {
	height: 100%;
}
#app {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: 100px 1fr;
	min-height: 100%;
}
#header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	padding: 10px;

	grid-column: 1 / span 5;

	/* overflow: hidden; */
}

#header #links a {
	font-size: 1.7rem;
	/* line-height: 0; */
	margin-left: 20px;
	padding: 0.15rem;

	border: 2px solid white;
	border-radius: 0.3rem;

	color: black;

	text-decoration: none;
}

#header #links a:hover {
	text-decoration: underline;
}

#header #links a.active {
	border-color: black;
}

#header .menu {
	display: none;
}

@media only screen and (max-width: 800px) {
	#header {
		flex-direction: column;
		position: relative;
	}

	#header #links {
		display: flex;
		flex-direction: column;
		visibility: hidden;
		height: 0;
		position: absolute;
		z-index: 1;
		background-color: white;
		width: 100%;
		margin-top: 90px;
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2);
		transition: 0.3s;
		overflow: hidden;
	}

	#header #links.show {
		visibility: visible;
		height: calc(4 * 57px);
		box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
	}

	#header #links a {
		font-size: 1.7rem;
		margin: 5px;
		padding: 0 0.3rem;
		height: 57px;

		border: unset;
	
		border-bottom: 2px solid black;
		border-radius: 0;
	}

	#header #links a:hover {
		/* background-color: gray; */
	}
	
	#header #links a.active {
		text-decoration: underline;
	}

	#header .menu {
		display: block;
		position: absolute;
		top: 33px;
		right: 25px;
	}
}
.menu {
	margin-top: -11px;
}

.menu .bar1, .menu .bar2, .menu .bar3 {
	height: 5px;
	margin-bottom: 6px;
}
.menu .bar1 > *, .menu .bar2 > *, .menu .bar3 > * {
	display: inline-block;
	width: 15px;
	height: 5px;
	background-color: #333;
	transition: 0.2s;
}

.menu.active .bar1 .seg1 {
	transform: rotate(45deg) translate(6px, 2px);
}

.menu.active .bar1 .seg2 {
	transform: rotate(-45deg) translate(-6px, 2px);
}

.menu.active .bar3 .seg1 {
	transform: rotate(-45deg) translate(6px, -2px);
}

.menu.active .bar3 .seg2 {
	transform: rotate(45deg) translate(-6px, -2px);
}

.menu.active .bar2 * {opacity: 0;}
#home {
	grid-column: 1 / span 5;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

#content-image {
	max-height: calc(100vh - 120px);
	max-width: 100%;
	scale: auto;

	margin: auto;
}

#home > .footer {
	height: 100px;
	display: flex;
	justify-content: center;
	flex-direction: row;
}

#home > .footer > a {
	display: inline-block;
	margin: auto 10px;
}

#home > .footer > .twitter > img {
	height: 80px;
	width: 80px;
}

#home > .footer > .instagram {
	background-color: purple;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: relative;
}

#home > .footer > .instagram > img {
	height: 50px;
	width: 50px;
	position: absolute;
	left: 15px;
	top: 15px;
}

