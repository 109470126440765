#header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	padding: 10px;

	grid-column: 1 / span 5;

	/* overflow: hidden; */
}

#header #links a {
	font-size: 1.7rem;
	/* line-height: 0; */
	margin-left: 20px;
	padding: 0.15rem;

	border: 2px solid white;
	border-radius: 0.3rem;

	color: black;

	text-decoration: none;
}

#header #links a:hover {
	text-decoration: underline;
}

#header #links a.active {
	border-color: black;
}

#header .menu {
	display: none;
}

@media only screen and (max-width: 800px) {
	#header {
		flex-direction: column;
		position: relative;
	}

	#header #links {
		display: flex;
		flex-direction: column;
		visibility: hidden;
		height: 0;
		position: absolute;
		z-index: 1;
		background-color: white;
		width: 100%;
		margin-top: 90px;
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2);
		transition: 0.3s;
		overflow: hidden;
	}

	#header #links.show {
		visibility: visible;
		height: calc(4 * 57px);
		box-shadow: 0px 16px 16px 0px rgba(0,0,0,0.2);
	}

	#header #links a {
		font-size: 1.7rem;
		margin: 5px;
		padding: 0 0.3rem;
		height: 57px;

		border: unset;
	
		border-bottom: 2px solid black;
		border-radius: 0;
	}

	#header #links a:hover {
		/* background-color: gray; */
	}
	
	#header #links a.active {
		text-decoration: underline;
	}

	#header .menu {
		display: block;
		position: absolute;
		top: 33px;
		right: 25px;
	}
}