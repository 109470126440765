.menu {
	margin-top: -11px;
}

.menu .bar1, .menu .bar2, .menu .bar3 {
	height: 5px;
	margin-bottom: 6px;
}
.menu .bar1 > *, .menu .bar2 > *, .menu .bar3 > * {
	display: inline-block;
	width: 15px;
	height: 5px;
	background-color: #333;
	transition: 0.2s;
}

.menu.active .bar1 .seg1 {
	-webkit-transform: rotate(45deg) translate(6px, 2px);
	transform: rotate(45deg) translate(6px, 2px);
}

.menu.active .bar1 .seg2 {
	-webkit-transform: rotate(-45deg) translate(-6px, 2px);
	transform: rotate(-45deg) translate(-6px, 2px);
}

.menu.active .bar3 .seg1 {
	-webkit-transform: rotate(-45deg) translate(6px, 2px);
	transform: rotate(-45deg) translate(6px, -2px);
}

.menu.active .bar3 .seg2 {
	-webkit-transform: rotate(45deg) translate(-6px, 2px);
	transform: rotate(45deg) translate(-6px, -2px);
}

.menu.active .bar2 * {opacity: 0;}