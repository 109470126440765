#home {
	grid-column: 1 / span 5;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

#content-image {
	max-height: calc(100vh - 120px);
	max-width: 100%;
	scale: auto;

	margin: auto;
}

#home > .footer {
	height: 100px;
	display: flex;
	justify-content: center;
	flex-direction: row;
}

#home > .footer > a {
	display: inline-block;
	margin: auto 10px;
}

#home > .footer > .twitter > img {
	height: 80px;
	width: 80px;
}

#home > .footer > .instagram {
	background-color: purple;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	position: relative;
}

#home > .footer > .instagram > img {
	height: 50px;
	width: 50px;
	position: absolute;
	left: 15px;
	top: 15px;
}
