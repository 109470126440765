@import url('https://fonts.googleapis.com/css2?family=Varta:wght@300;400;500;600;700&display=swap');

body {
	margin: 0;
	font-family: 'Varta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body, #root {
	height: 100%;
}